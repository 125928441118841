<template>
  <div>
    <div class="columns">
      <div class="column">
        <p class="title has-text-grey-light is-6">Colour changes</p>
      </div>
      <div class="column is-narrow">
        <p class="title is-6">
          <span class="has-text-warning" v-if="changes"
            >{{ changes }} change(s) reported</span
          >
          <span class="has-text-success" v-else>None</span>
        </p>
      </div>
    </div>
    <div v-if="changes" class="box">
      <div class="columns">
        <div class="column">
          <p class="has-text-grey-light">Original colour</p>
          <p class="subtitle is-4">{{ items[items.length - 1].lastColour }}</p>
        </div>
        <div class="column">
          <p class="has-text-grey-light">Total changes</p>
          <p class="subtitle is-4">{{ changes }}</p>
        </div>
      </div>
      <table class="table is-fullwidth is-striped">
        <thead>
          <tr>
            <td>Colour change</td>
            <td>Date of change</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td width="50%">
              <div class="columns is-vcentered is-mobile">
                <div class="column is-narrow">
                  <button class="button has-shadow">
                    {{ item.lastColour }}
                  </button>
                </div>
                <div class="column is-narrow">
                  <span class="icon has-text-info">
                    <i class="fal fa-arrow-right" />
                  </span>
                </div>
                <div class="column is-narrow">
                  <button class="button has-shadow">
                    {{ getColour(index) }}
                  </button>
                </div>
              </div>
            </td>
            <td width="50%">{{ formatDate(item.dateOfTransaction) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'AutoCheckColourChange',
  props: {
    data: {
      type: Object,
      required: true
    },
    current: {
      type: String,
      required: true
    }
  },
  computed: {
    changes() {
      return this.data.colourChangeCount
    },
    items() {
      return this.data.colourChanges1
    }
  },
  methods: {
    formatDate,
    getColour(index) {
      return index === 0 ? this.current : this.items[index - 1].lastColour
    }
  }
}
</script>
